import styled, { css } from 'styled-components';
import { ThemeProps } from '@/styles/global';

const style = css`
  background-color: ${(props: ThemeProps) => props.theme.softGreenColor};
  border-radius: 0.25rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(props: ThemeProps) => props.theme.whiteColor};
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: 0 0 1.5rem rgba(19, 187, 136, 0.2);
  &:hover {
    box-shadow: 0 0 1rem rgba(19, 187, 136, 0.4);
  }
  &:active {
    box-shadow: 0 0 1.5rem rgba(19, 187, 136, 0.2);
    transform: translateY(0.0625rem);
  }
`;

export const StyledButton = styled.button`
  ${style}
`;

export const StyledLinkButton = styled.a`
  ${style};
  display: flex;
  align-items: center;
  justify-content: center;
`;
