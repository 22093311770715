const jobTitleForUrl = (title: string) => {
  return title
    .toLowerCase()
    .trim()
    .replace(/[!&,.?:;()–]/g, ``)
    .replace(/[/\\]/g, `-`)
    .replaceAll(` `, `-`);
};

export default jobTitleForUrl;
