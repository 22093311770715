import styled from 'styled-components';

export const DescriptionContainer = styled.div`
  & > div {
    display: flex;
    flex-direction: column;
    line-height: 1.5rem !important;
    font-weight: 300 !important;
    ul,
    ol {
      display: flex !important;
      flex-direction: column !important;
      padding-left: 1.7rem !important;
      margin: 0;
    }
    li p {
      margin: 0 !important;
    }
    li {
      position: relative !important;
    }
    span {
      display: block !important;
    }
  }
`;
