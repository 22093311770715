import { connectStats } from 'react-instantsearch-dom';
import { StyledP } from './StatsComponent.styles';
import { JOBS_FOUND_SPAN } from '@/utils/constants';

const Stats = ({ nbHits }: { nbHits: number }) => (
  <StyledP>
    <span id={JOBS_FOUND_SPAN}>{nbHits.toLocaleString()}</span>
    {` `}
    {nbHits > 1 ? `jobs ` : `job `}found
  </StyledP>
);

const CustomStats = connectStats(Stats);
export default CustomStats;
