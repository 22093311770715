import { useContext, useState } from 'react';
import Button from '../Button/Button';
import SearchResultDescriptionComponent from '../SearchResultDescription/SearchResultDescriptionComponent';
import {
  StyledLabelsDiv,
  StyledTechDiv,
  StyledAdvantageDiv,
  ButtonContainer,
  Link,
} from './SearchResultComponent.styles';
import { TResult } from '../ResultsComponent/ResultsComponent';
import { CategoryContext, Context } from '@/utils/context';
import { ContextProps } from '@/utils/contextProps';
import { JOBS_FOUND_SPAN } from '@/utils/constants';
import SearchResultComponentItem from './SearchResultComponentItem';
import jobTitleForUrl from '@/utils/jobTitleForUrl';

interface Props {
  result: TResult;
}

const SearchResultComponent = ({ result }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentCategory = useContext(CategoryContext);
  const { tags, setTags, perks, setPerks, resetPage, addQuery }: ContextProps =
    useContext(Context);

  const clickHandler = () => {
    const url = new URL(window.location.toString());
    const jobTitle = jobTitleForUrl(result.title);
    if (!isOpen) {
      url.pathname = url.pathname.includes(currentCategory?.categoryLink)
        ? `${currentCategory?.categoryLink}/${result.objectID}-${jobTitle}`
        : `/${result.objectID}-${jobTitle}`;
    } else {
      url.pathname = url.pathname.replaceAll(`/${result.objectID}`, ``);
      url.pathname = url.pathname.replaceAll(`-${jobTitle}`, ``);
    }
    window.history.pushState({}, ``, url);
    setIsOpen((prevState) => !prevState);
  };

  return (
    <SearchResultComponentItem
      result={result}
      isOpen={isOpen}
      clickHandler={clickHandler}
      tagsAndPerks={
        <StyledLabelsDiv>
          {result._tags?.length !== 0 &&
            result._tags?.map((tag, i) => (
              <StyledTechDiv
                onClick={(e) => {
                  e.stopPropagation();
                  if (!tags.includes(tag)) {
                    if (!currentCategory?.tags.includes(tag)) {
                      addQuery(`tags`, tag);
                    }
                    setTags((prevState: string[]) => {
                      return [...prevState, tag];
                    });
                    resetPage();
                    document?.getElementById(JOBS_FOUND_SPAN)?.scrollIntoView({
                      behavior: `smooth`,
                      block: `center`,
                    });
                  }
                }}
                key={`${tag}-${i}`}
              >
                {tag}
              </StyledTechDiv>
            ))}
          {result.perks?.length !== 0 &&
            result.perks?.map((perk) => (
              <StyledAdvantageDiv
                onClick={(e) => {
                  e.stopPropagation();
                  if (!perks.includes(`perks:${perk}`)) {
                    addQuery(`perks`, perk.toLowerCase());
                    setPerks((prevState: string[]) => {
                      return [...prevState, `perks:${perk}`];
                    });
                    resetPage();
                  }
                }}
                key={perk}
              >
                {perk.replace(/_/g, ` `).toLowerCase()}
              </StyledAdvantageDiv>
            ))}
        </StyledLabelsDiv>
      }
      applyButton={
        <ButtonContainer
          isOpen={isOpen}
          onClick={(e: any) => e.stopPropagation()}
        >
          <Link
            target="_blank"
            href={result.providerUrl || ``}
            rel="noreferrer"
            aria-label="apply-btn"
          >
            <Button>Apply</Button>
          </Link>
        </ButtonContainer>
      }
      description={
        <SearchResultDescriptionComponent
          objectID={result.objectID}
          providerUrl={result.providerUrl}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      }
    />
  );
};

export default SearchResultComponent;
