import styled, { keyframes } from 'styled-components';
import { ThemeProps } from '@/styles/global';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  display: inline-block;
  margin-bottom: 0.5rem;
  &:after {
    content: ' ';
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.5rem;
    border-radius: 50%;
    border: 4px solid ${(props: ThemeProps) => props.theme.softGreenColor};
    border-color: ${(props: ThemeProps) => props.theme.softGreenColor}
      transparent ${(props: ThemeProps) => props.theme.softGreenColor}
      transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;

export const CenterLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const MediumLoader = styled(Loader)`
  margin-bottom: 0;
  &:after {
    width: 1.125rem;
    height: 1.125rem;
    border-width: 0.25rem;
  }
`;

export const SmallLoader = styled(Loader)`
  margin-bottom: 0;
  height: 1.5rem;
  display: flex;
  align-items: center;
  &:after {
    width: 0.625rem;
    height: 0.625rem;
    margin: 0;
    border-width: 0.125rem;
  }
`;
