import styled from 'styled-components';
import { ThemeProps } from '@/styles/global';
import { StylesProps } from '@/utils/stylesProps';

type Props = StylesProps & ThemeProps;

interface IStyledImageDiv {
  defaultPlaceholder: boolean;
}

export const StyledResultContainer = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
`;

export const StyledResultDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  min-height: 8rem;
  margin-bottom: 1.5rem;
  background-color: ${(props: Props) =>
    props.isOpen ? `rgba(255, 208, 42, 0.03)` : props.theme.whiteColor};
  box-shadow: 0 0 0.25rem rgba(89, 115, 114, 0.16);
  border-radius: 0.25rem;
  border: 0.0625rem solid transparent;
  border-color: ${(props: Props) =>
    props.isOpen ? props.theme.yellowColor : `transparent`};
  border-left: 0.25rem solid
    ${(props: Props) =>
      props.isOpen ? props.theme.yellowColor : props.theme.softGreenColor};
  &:hover {
    background-color: rgba(255, 208, 42, 0.03);
    border-color: ${(props: ThemeProps) => props.theme.yellowColor};
    box-shadow: 0 0 0.25rem rgba(255, 184, 42, 0.22);
    border-left: 0.25rem solid ${(props: ThemeProps) => props.theme.yellowColor};
    & button {
      display: block;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

export const StyledImageDiv = styled.div<IStyledImageDiv>`
  width: 5rem;
  margin: 1.25rem 1rem 0 1rem;
  flex-shrink: 0;
  ${(props) =>
    props.defaultPlaceholder &&
    `justify-content: center;
      display: flex;`}
  & img {
    width: inherit;
    height: inherit;
    border-radius: 0.25rem;
    ${(props) =>
      props.defaultPlaceholder &&
      `
      width: 100%;
      height: fit-content;
    `}
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledMobileImageDiv = styled.div`
  float: left;
  margin: 1rem 1rem 0;
  width: 2.5rem;
  overflow: hidden;
  & img {
    width: inherit;
    height: inherit;
    border-radius: 0.25rem;
  }
  @media (min-width: 769px) {
    display: none;
  }
`;

export const StyledInfoDiv = styled.div`
  flex-grow: 1;
  height: 100%;
`;

export const StyledInfoP = styled.p`
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: ${(props: ThemeProps) => props.theme.blackColor};
  margin: 1rem 0 0.1875rem 0;
  @media (max-width: 768px) {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
`;

export const StyledInfoTitle = styled.h2`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  color: ${(props: ThemeProps) => props.theme.blackColor};
  margin: 0.1875rem 0 0.375rem 0;
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 1rem;
  }
`;

export const StyledWorkConditionsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.375rem 0 0.75rem 0;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: ${(props: ThemeProps) => props.theme.blackColor};
  & span {
    display: flex;
    margin-right: 1.5rem;
    @media (max-width: 992px) {
      margin-right: 0.375rem;
    }

    & svg {
      margin-right: 0.375rem;
      @media (min-width: 769px) and (max-width: 992px) {
        margin-right: 0.15rem;
      }
      @media (max-width: 438px) {
        margin: 0 0.1rem 0 0.3rem !important;
      }
    }
  }
  @media (max-width: 768px) {
    margin: 0.375rem 1rem 0.75rem 1rem;
    flex-wrap: wrap;
  }
`;

export const StyledLabelsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0.75rem 0;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  @media (max-width: 768px) {
    margin: 0.75rem 1rem;
  }
`;

export const StyledTechDiv = styled.div`
  height: 100%;
  background: rgba(16, 168, 35, 0.13);
  border-radius: 0.25rem;
  margin: 0.25rem 0.5rem 0.25rem 0;
  padding: 0.25rem 0.75rem;
  color: ${(props: ThemeProps) => props.theme.softGreenColor};
  &:hover {
    transform: scale(1.08, 1.08);
  }
`;

export const StyledAdvantageDiv = styled.div`
  height: 100%;
  background: rgba(19, 184, 182, 0.13);
  border-radius: 0.25rem;
  margin: 0.25rem 0.5rem 0.25rem 0;
  padding: 0.25rem 0.75rem;
  color: ${(props: ThemeProps) => props.theme.turquoiseColor};
  &:hover {
    transform: scale(1.05, 1.05);
  }
`;

export const StyledDivDate = styled.div`
  height: 100%;
  width: auto;
  padding: 1rem 1rem 0 0;
  text-align: end;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: ${(props: ThemeProps) => props.theme.darkGrayColor};
  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledMobileDivDate = styled.div`
  height: 100%;
  min-width: 6rem;
  padding: 0 0 1rem 1rem;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: ${(props: ThemeProps) => props.theme.darkGrayColor};
  @media (min-width: 769px) {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 6.8125rem;
  height: 3rem;
  margin-top: 0.875rem;
  & button {
    display: ${(props: Props) => (props.isOpen ? `block` : `none`)};
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 4.5rem;
  }
  @media (max-width: 768px) {
    margin: 1.5rem 0;
    & button {
      max-width: none;
    }
  }
`;

export const Link = styled.a`
  width: 100%;
`;
