import React, { useEffect, useRef } from 'react';
import {
  connectInfiniteHits,
  connectStateResults,
} from 'react-instantsearch-dom';
import { InfiniteHitsProvided } from 'react-instantsearch-core';
import SearchResultComponent from '../SearchResult/SearchResultComponent';
import {
  Container,
  NoResultsDescription,
  NoResultsTitle,
} from './ResultsComponent.styles';
import { Loader } from '@/components/Loader/Loader.styles';

export interface TResult {
  objectID: string;
  bannerUrl?: string;
  company: string;
  companyLogo: string;
  title: string;
  _tags: string[] | null;
  perks: string[] | null;
  applyUrl: string;
  providerUrl: string;
  minCompensation: number;
  maxCompensation: number;
  locationCity: string | null;
  locationCountry: string | null;
  remote: boolean | null;
  updatedDate: string;
  remoteRestriction: string[] | [] | null;
  description?: string;
}

const PREFETCH_BLOCK_LEVEL = 10;

const LoadingIndicator = connectStateResults(
  ({ isSearchStalled, searchResults }) => {
    return isSearchStalled ? (
      <Loader />
    ) : !(searchResults && searchResults.nbHits !== 0) ? (
      <>
        <NoResultsTitle>Jobs Not Found!</NoResultsTitle>
        <NoResultsDescription>
          Explore our other listings for fresh opportunities. 🕵️‍♂️🔍
        </NoResultsDescription>
      </>
    ) : null;
  },
);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ResultsComponent: React.FC<InfiniteHitsProvided<TResult>> =
  connectInfiniteHits(({ hits, hasMore, refineNext }) => {
    const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

    useEffect(() => {
      if (!hasMore) return;

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && hasMore) {
            refineNext();
          }
        });
      });

      observer.observe(ref?.current);

      return () => {
        observer.disconnect();
      };
    }, [hasMore, refineNext]);

    const preFetchBlock = <div ref={ref} style={{ zIndex: 10 }} />;

    return (
      <Container>
        <LoadingIndicator />
        {!!hits?.length &&
          hits.map((hit, index) => (
            <React.Fragment key={`${hit.objectID}-index`}>
              <SearchResultComponent key={hit.objectID} result={hit} />
              {index === hits.length - PREFETCH_BLOCK_LEVEL && preFetchBlock}
            </React.Fragment>
          ))}
        {preFetchBlock}
      </Container>
    );
  });

export default ResultsComponent;
