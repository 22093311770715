import Link from 'next/link';
import { StyledButton, StyledLinkButton } from './Button.styles';

interface LinkProps {
  children: string;
  href: string;
}

export const LinkButton = ({ children, href }: LinkProps) => (
  <Link href={href} passHref>
    <StyledLinkButton>{children}</StyledLinkButton>
  </Link>
);

interface Props {
  children: string;
  clickHandler?: () => void;
  disabled?: boolean;
}

const Button = ({ children, clickHandler, disabled }: Props) => (
  <StyledButton onClick={clickHandler} disabled={disabled}>
    {children}
  </StyledButton>
);

export default Button;
