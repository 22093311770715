import styled from 'styled-components';
import { ThemeProps } from '@/styles/global';

export const StyledP = styled.p`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(props: ThemeProps) => props.theme.blackColor};
  span {
    font-weight: 600;
    color: ${(props: ThemeProps) => props.theme.softGreenColor};
  }
  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
  @media (max-width: 438px) {
    margin-right: 0.5rem;
  }
`;
