// Define the maximum radius for a geo search (in meters)
export const aroundRadius = 1000000;

//Maximum number of _tags displayed in the drop-down list
export const maxFacetHits = 10;

//Maximum number of hits to return for each request
export const hitsPerPage = 24;

//Items for algolia sorting
export const sortingItems = [
  { value: `verified-jobs`, label: `Matches` },
  { value: `verified-jobs_updatedDate_desc`, label: `Newest` },
  { value: `verified-jobs_minCompensation_desc`, label: `Salary` },
];
