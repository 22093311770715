import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 4rem;
  width: 100%;
  @media (min-width: 769px) and (max-width: 992px) {
    margin-left: 0.625rem;
  }
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export const StyledDiv = styled.div`
  width: 100%;
  height: 2.75rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-between;
  &:nth-child(2) {
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    height: 2.5rem;
    margin-bottom: 1.5rem;
  }
`;
