import { ReactElement, useState } from 'react';
import {
  StyledResultContainer,
  StyledResultDiv,
  StyledImageDiv,
  StyledMobileImageDiv,
  StyledInfoDiv,
  StyledInfoP,
  StyledInfoTitle,
  StyledWorkConditionsDiv,
  StyledDivDate,
  StyledMobileDivDate,
} from './SearchResultComponent.styles';
import { TResult } from '../ResultsComponent/ResultsComponent';
import { CreditCardIcon, BriefcaseIcon, LocationIcon } from '@/icons/index';

interface Props {
  result: TResult;
  isOpen?: boolean;
  clickHandler?: () => void;
  tagsAndPerks?: ReactElement;
  applyButton?: ReactElement;
  description?: ReactElement;
}

const DEFAULT_LOGO_IMAGE = `default_job_placeholder.png`;

const getNumberOfDays = (start: number, end: number) => {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
};

const getSalary = (minCompensation: number, maxCompensation: number) => {
  let min = ``;
  let max = ``;
  if (minCompensation > 1000000) {
    min = `$${minCompensation / 1000000}kk`;
  } else if (minCompensation > 1000) {
    min = `$${minCompensation / 1000}k`;
  } else {
    minCompensation && (min = `$${minCompensation}`);
  }

  if (maxCompensation > 1000000) {
    max = `$${maxCompensation / 1000000}kk`;
  } else if (maxCompensation > 1000) {
    max = `$${maxCompensation / 1000}k`;
  } else {
    maxCompensation && (max = `$${maxCompensation}`);
  }

  const both = min && max;
  const single = min || max;

  return both ? `${min}-${max}/year` : `${single}/year`;
};

const SearchResultComponentItem = ({
  result,
  isOpen = false,
  clickHandler,
  tagsAndPerks,
  applyButton,
  description,
}: Props) => {
  const [useDefaultPlaceholder, setUseDefaultPlaceholder] = useState(false);
  const remoteRestriction =
    result.remoteRestriction && result.remoteRestriction.length
      ? ` - ${result.remoteRestriction.join(`, `)}`
      : null;
  const date = getNumberOfDays(Number(result.updatedDate), Date.now());
  const salary = getSalary(result.minCompensation, result.maxCompensation);

  const imageErrorHandler = (e: any) => {
    e.target.src = `/${DEFAULT_LOGO_IMAGE}`;
    setUseDefaultPlaceholder(true);
  };

  return (
    <StyledResultDiv isOpen={isOpen}>
      <StyledResultContainer onClick={clickHandler}>
        <StyledImageDiv
          defaultPlaceholder={useDefaultPlaceholder || !result.companyLogo}
        >
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            loading="lazy"
            width="100%"
            height="100%"
            src={
              result.companyLogo ? result.companyLogo : `/${DEFAULT_LOGO_IMAGE}`
            }
            onError={imageErrorHandler}
            alt={result.company + `-icon`}
          />
        </StyledImageDiv>
        <StyledInfoDiv>
          <StyledMobileImageDiv>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              loading="lazy"
              width="100%"
              height="100%"
              src={
                result.companyLogo
                  ? result.companyLogo
                  : `/${DEFAULT_LOGO_IMAGE}`
              }
              onError={imageErrorHandler}
              alt={result.company + `-icon`}
            />
          </StyledMobileImageDiv>
          <StyledInfoP>{result.company}</StyledInfoP>
          <StyledInfoTitle>{result.title}</StyledInfoTitle>
          <StyledWorkConditionsDiv>
            {result.minCompensation && (
              <span>
                <CreditCardIcon />
                {salary}
              </span>
            )}
            {result.remote && (
              <span>
                <BriefcaseIcon />
                Remote
                {remoteRestriction}
              </span>
            )}
            {!remoteRestriction ? (
              <span>
                <LocationIcon />
                {result.locationCity
                  ? result.locationCountry
                    ? `${result.locationCity}, ${result.locationCountry}`
                    : result.locationCity
                  : `Anywhere`}
              </span>
            ) : null}
          </StyledWorkConditionsDiv>
          {tagsAndPerks}
        </StyledInfoDiv>
        <StyledDivDate>
          {date ? `${date} days ago` : `today`}
          {applyButton}
        </StyledDivDate>
      </StyledResultContainer>
      <>{description}</>
      <StyledMobileDivDate>
        {date ? `${date} day ago` : `today`}
      </StyledMobileDivDate>
    </StyledResultDiv>
  );
};

export default SearchResultComponentItem;
