import styled, { css } from 'styled-components';
import { ThemeProps } from '@/styles/global';
import { StylesProps } from '@/utils/stylesProps';

type Props = StylesProps & ThemeProps;

export const StyledResultDescription = styled.div`
  padding: 0 7.25rem;
  overflow: hidden;
  max-height: 0;
  width: 0;
  transition-property: max-height, border, margin-bottom;
  transition-duration: 0.1s;
  transition-timing-function: ease;
  border-radius: 0 0 0.25rem 0.25rem;
  color: ${(props: ThemeProps) => props.theme.blackColor};
  opacity: 0;
  white-space: pre-line;
  ${(props: Props) =>
    props.isOpen &&
    css`
      max-height: 500rem;
      height: auto;
      width: 100%;
      opacity: 1;
    `}
  }
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  height: 3rem;
  margin: 2rem 0;
  @media (max-width: 768px) {
    margin: 1.5rem 0;
  }
`;

export const Link = styled.a`
  width: 100%;
  max-width: 11.25rem;
  @media (max-width: 768px) {
    max-width: none;
  }
`;
