import { useEffect, useState, useRef } from 'react';
import { connectSortBy } from 'react-instantsearch-dom';
import {
  SortingContainer,
  StyledSortBy,
  StyledLi,
} from './SortingComponent.styles';
import { DropdownArrowDown, DropdownArrowUp } from '@/icons';
import { DropdownMenu } from './SortingComponent.styles';
import useOnClickOutside from '@/hooks/useOnClickOutside';

interface Props {
  items: {
    label: string;
    value: string;
    isRefined: boolean;
  }[];
  refine: (value: string) => void;
}

const SortBy = ({ items, refine }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(``);
  const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useEffect(() => {
    items.forEach((item) => {
      if (item.isRefined) {
        setCurrentItem(item.label);
      }
    });
  }, [items]);
  useOnClickOutside(wrapperRef, () => setIsOpen(false));
  return (
    <SortingContainer ref={wrapperRef}>
      <p>Sort by:</p>
      <StyledSortBy
        isOpen={isOpen}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        {currentItem}
        {isOpen ? <DropdownArrowUp /> : <DropdownArrowDown />}
      </StyledSortBy>
      <DropdownMenu isOpen={isOpen}>
        {items.map((item) => (
          <StyledLi
            isRefined={item.isRefined}
            key={item.value}
            onClick={(e: any) => {
              e.preventDefault();
              setIsOpen(false);
              refine(item.value);
            }}
          >
            {item.label}
          </StyledLi>
        ))}
      </DropdownMenu>
    </SortingContainer>
  );
};

const SortingComponent = connectSortBy(SortBy);

export default SortingComponent;
