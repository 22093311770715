import { useState, useEffect, useContext } from 'react';
import Button from '../Button/Button';
import {
  StyledResultDescription,
  ButtonContainer,
  Link,
} from './SearchResultDescription.styles';
import Markdown from 'markdown-to-jsx';
import { CenterLoaderContainer, MediumLoader } from '../Loader/Loader.styles';
import { DescriptionContainer } from '../DescriptionContainer/DescriptionContainer.styles';
import { JOBS_FOUND_SPAN } from '@/utils/constants';
import { IsJobPageContext } from '@/utils/context';
import { searchClient, indexName } from '@/components/AppWrapper/AppWrapper';

interface Props {
  objectID: string;
  isOpen: boolean;
  providerUrl: string;
  setIsOpen: (isOpen: boolean) => void;
}

const index = searchClient.initIndex(indexName);

const SearchResultDescriptionComponent = ({
  objectID,
  isOpen,
  providerUrl,
  setIsOpen,
}: Props) => {
  const [description, setDescription] = useState(``);
  const isJobPage = useContext(IsJobPageContext);

  useEffect(() => {
    if (isOpen && !description) {
      index
        .getObject(objectID, {
          attributesToRetrieve: [`description`],
        })
        .then((res: any) => {
          setDescription(res?.description);
        });
    }
  }, [isOpen, objectID, description]);

  useEffect(() => {
    if (isJobPage) {
      setIsOpen(true);
      if (description) {
        setTimeout(() => {
          document?.getElementById(JOBS_FOUND_SPAN)?.scrollIntoView({
            behavior: `smooth`,
          });
        }, 100);
      }
    }
  }, [isJobPage, description]);

  const contentDescription =
    description.startsWith(`<`) &&
    description
      .replace(/\r?\n|\r| {2,}|<body>|<\/body>|<html>|<\/html>/g, ``)
      .replace(
        /<br><br>(?=<br>{1,})|<div><br><\/div>(?=<div><br><\/div>{1,})/g,
        ``,
      );

  return (
    <StyledResultDescription isOpen={isOpen}>
      {!description ? (
        <CenterLoaderContainer>
          <MediumLoader />
        </CenterLoaderContainer>
      ) : (
        <DescriptionContainer>
          {contentDescription ? (
            <div
              dangerouslySetInnerHTML={{
                __html: contentDescription,
              }}
            />
          ) : (
            <Markdown>{description}</Markdown>
          )}
        </DescriptionContainer>
      )}
      <ButtonContainer>
        <Link target="_blank" href={providerUrl || ``} rel="noreferrer">
          <Button>Apply for a Job</Button>
        </Link>
      </ButtonContainer>
    </StyledResultDescription>
  );
};

export default SearchResultDescriptionComponent;
