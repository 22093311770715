import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const NoResultsTitle = styled.p`
  font-weight: 600;
  text-align: center;
  font-size: 2rem;
  opacity: 0.2;
  margin: 1.5rem 0 2rem;
`;

export const NoResultsDescription = styled.p`
  font-weight: 400;
  text-align: center;
  font-size: 1.2rem;
  opacity: 0.5;
  margin: 0 auto 4rem;
  max-width: 80%;
  line-height: 1.5;
`;
