import React from 'react';
import ResultsComponent from '../ResultsComponent/ResultsComponent';
import { Container, StyledDiv } from './MainContainer.styles';

import CustomStats from '../StatsComponent/StatsComponent';
import SortingComponent from '../SortingComponent/SortingComponent';
import { sortingItems } from '@/utils/algoliaVariables';

const MainContainer = () => {
  return (
    <Container>
      <StyledDiv>
        <CustomStats />
        <SortingComponent
          defaultRefinement="verified-jobs"
          items={sortingItems}
        />
      </StyledDiv>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ResultsComponent />
    </Container>
  );
};

export default MainContainer;
