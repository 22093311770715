import styled from 'styled-components';
import { ThemeProps } from '@/styles/global';
import { StylesProps } from '@/utils/stylesProps';

type Props = StylesProps & ThemeProps;

export const SortingContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  position: relative;
  & p {
    cursor: default;
    margin-right: 1rem;
    color: ${(props: ThemeProps) => props.theme.blackColor};
  }
  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
`;

export const StyledSortBy = styled.div`
  display: flex;
  box-shadow: 0 0 0.25rem rgba(89, 115, 114, 0.16);
  border-radius: 0.25rem;
  padding: 0.625rem 1rem 0.625rem 1.5rem;
  color: ${(props: ThemeProps) => props.theme.softGreenColor};
  cursor: pointer;
  border: 0.0625rem solid
    ${(props: Props) =>
      props.isOpen ? props.theme.softGreenColor : `transparent`};
  width: 8.875rem;
  align-items: center;
  justify-content: space-between;
  &:hover {
    box-shadow: 0 0 0.25rem rgba(89, 115, 114, 0.7);
  }
  @media (max-width: 768px) {
    width: 7.375rem;
    padding: 0.625rem 1rem;
  }
`;

export const DropdownMenu = styled.ul`
  display: ${(props: Props) => (props.isOpen ? `flex` : `none`)};
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  flex-direction: column;
  padding: 1.125rem 0 1.125rem;
  justify-content: space-around;
  width: 8.875rem;
  list-style: none;
  background-color: ${(props: Props) => props.theme.whiteColor};
  color: ${(props: ThemeProps) => props.theme.blackColor};
  box-shadow: 0 0 0.25rem rgba(89, 115, 114, 0.16);
  border-radius: 0.25rem;
  @media (max-width: 768px) {
    width: 7.375rem;
    padding: 0.625rem 0 0.625rem;
  }
`;

export const StyledLi = styled.li`
  cursor: pointer;
  padding: 0.375rem 1.5rem;
  color: ${(props: Props) =>
    props.isRefined ? props.theme.softGreenColor : props.theme.blackColor};
  font-size: 0.875rem;
  &:hover {
    background-color: rgba(229, 229, 229, 0.3);
  }
  @media (max-width: 768px) {
    padding: 0.375rem 1rem;
  }
`;
